import '../styles/globals.css'
import 'bootstrap/dist/css/bootstrap.css';
import cookie from 'js-cookie';
import axios from 'axios';

// import { datadogRum } from '@datadog/browser-rum';

// datadogRum.init({
//     applicationId: '1ddc1455-430c-414d-9dc5-d00ea901a64a',
//     clientToken: 'pub57df2a4f6f2025bbf6c7c207dde200df',
//     site: 'datadoghq.eu',
//     service:'headless',
//     env:'prod',
//     // Specify a version number to identify the deployed version of your application in Datadog 
//     // version: '1.0.0', 
//     sessionSampleRate:100,
//     sessionReplaySampleRate: 20,
//     trackUserInteractions: true,
//     trackResources: true,
//     trackLongTasks: true,
//     defaultPrivacyLevel:'mask-user-input'
// });
    
// datadogRum.startSessionReplayRecording();

import dynamic from 'next/dynamic';

import { useEffect, useState } from 'react';
const Navbar = dynamic(()=>import('../src/components/navbar')) 
const Footer = dynamic(()=>import('../src/components/footer'))
const TrialookFooter = dynamic(()=>import('../src/components/tiralookfooter')) 


import $ from "jquery";

// import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
// config.autoAddCss = false
import { useRouter } from 'next/router';
import Head from 'next/head';
import config from '../config';
import Loader from '../src/components/loader';

import { GA_TRACKING_ID } from '../gtag';
function MyApp({ Component, pageProps }) {
	const [loading, setLoading] = useState(false);
	const router = useRouter();
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     try {
  //       if(event?.target?.activeElement?.href && event.target.activeElement?.href.includes('orientbell')){
  //         return
  //       }
  //         const message = "You have unsaved. Are you sure you want to leave?";
  //         event.returnValue = message; // Standard for most browsers
  //         return message; // For some older browsers
        
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   };
    
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [router]);
	useEffect(() => {
    // handlePincodeFetch()
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:5200084,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    const visitedPages = JSON.parse(localStorage.getItem('visitedPages')) || [];
    let currentPage = `https://www.orientbell.com${router.asPath}`
    let options = { timeZone: "Asia/Kolkata", hour12: true, hour: "2-digit", minute: "2-digit", year: 'numeric', month: '2-digit', day: '2-digit' };
    let currentDateTime = new Intl.DateTimeFormat('en-IN', options).format(new Date());  
    if(visitedPages[visitedPages.length-1]?.split('(')[0]  != currentPage){
      visitedPages.push(`${currentPage}(${currentDateTime})`);
    }
    if(!localStorage.getItem('firstVistedPage')){
      localStorage.setItem('firstVistedPage', JSON.stringify(`${currentPage}(${currentDateTime})`)); 
    }
    localStorage.setItem('visitedPages', JSON.stringify(visitedPages));
     setTimeout(()=>{
        const anchorTags = document.querySelectorAll('a'); // Select all anchor tags
      anchorTags.forEach(anchor => {
        const originalHref = anchor.getAttribute('href');
        if(originalHref && router.asPath.includes('/investor')){
          return
        }
        if(originalHref && originalHref.includes("technical_specification")){
          anchor.addEventListener('click', function(event) {
            event.preventDefault();
            window.location.href = `https://server.orientbell.com/media/pdf/technical_specification/${originalHref.split('/').pop()}`
            return
          });
         
        }
        if ((originalHref && originalHref.includes("https://server.orientbell.com/")) && router.asPath != '/download-export-catalogue' && router.asPath != '/download-catalogue' && !originalHref.includes("technical_specification")) {
          anchor.addEventListener('click', function(event) {
            event.preventDefault();
            const newHref = originalHref.replace("https://server.orientbell.com/", "https://www.orientbell.com/");
            window.location.href = newHref;
          });
        }
      });
      const imgElements = document.querySelectorAll('img');
      imgElements.forEach((anchor) => {
        const href = anchor.getAttribute('src');
        if (href && href.includes('https://www.orientbell.com/media/')) {
          const updatedSrc = href.replace('https://www.orientbell.com/media/', 'https://server.orientbell.com/media/');
          // Update the href attribute
          anchor.setAttribute('src', updatedSrc);
        }
      });
      },1000)
	  const handleStart = () => {
		// setLoading(true);
	  };
  
	  const handleComplete = () => {
    document.querySelector('meta[name="Cache-Control"]').setAttribute('content', 'no-store, must-revalidate');
		setLoading(false);
	  };
  
	  // router.events.on('routeChangeStart', handleStart);
	  router.events.on('routeChangeComplete', handleComplete);
	  router.events.on('routeChangeError', handleComplete);
  
	  return () => {
		// router.events.off('routeChangeStart', handleStart);
		router.events.off('routeChangeComplete', handleComplete);
		router.events.off('routeChangeError', handleComplete);
	  };
	}, [router]);
  useEffect(()=>{
    !function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    fbq('init', '331216545972508');
    // New Pixel id - OBL own account
    fbq('init', '1062876005164008');
    fbq('track', 'PageView');
    require("bootstrap/dist/js/bootstrap");
//    require("react-image-magnify/dist/styles.css") 
},[])
if(typeof document !=='undefined'){
  
  $(window).scroll(function(){
		var sticky = $('.header-section'),
		scroll = $(window).scrollTop();
		if (scroll >=94) {sticky.addClass('fixed');}
		else {sticky.removeClass('fixed')};
	});
	
	// $(window).ready(function(){
	// $('.f-heading').click(function(){
	// $(this).parent().siblings().find('.f-heading').removeClass('active-link');
	// var addclass = $(this).toggleClass('active-link');
	// $(this).find('.smooth-check').css({"scroll-behavior": "smooth"});
	// console.log($(this).find('.smooth-check').attr('class'));
	// });
	// });
	
	// $(document).ready(function(){
	//   $(".mobile-icon").click(function(){
	// 	$(this).toggleClass("mobile-icon-open");
	//   });
	// });
	
	$(document).ready(function(){
	$('.menu-arrow').click(function(){
	$(this).parent().siblings().find('.menu-arrow').removeClass('sublink-open');
	var addclass = $(this).toggleClass('sublink-open');
	});
	});
	
	$(document).ready(function(){
	$('.titleHead').click(function(){
	$(this).parent().siblings().find('.titleHead').removeClass('submenu-open');
	var addclass = $(this).toggleClass('submenu-open');
	});
	});
	
	$(document).ready(function(){
		$('#search-box-icon a').click(function(){
			
			$('#mobile_search_div').addClass("m_searchbar_a");
		})

		$('#m_s_close_btn').click(function(){
			$('#mobile_search_div').removeClass("m_searchbar_a");
			
		});
	});
	
	
	$('.find-dealer-row form button.locate-btn').on('click',function(event) {
		event.preventDefault();
        var pincode = $(this).parent().find('input').val();
		
        if (!pincode) {
            alert('Please type a valid pincode');
            return false;
        }
        var url = 'https://www.orientbell.com/store-locator?pincode='+pincode;
        window.location = url;
    });
	
	$('.sort-filter-col').on('click', function(){
		$('.main-sidebar').toggleClass('open-filter');
	});
	
	$(document).ready(function(){
	  $(".tilecountis-bx ul li").click(function(){
		$(this).toggleClass("active");
	  });
	});
	
}
const orgSxhema = () => {
  return {
    __html: `
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "legalName": "Orient Bell Limited",
      "url": "https://www.orientbell.com/",
      "description": "Explore Orientbell, India's leading tile brand, with 100% Tiles, 0% Celebs. Get style & class with a wide range of premium quality tiles at the best price. Shop now!",
      "logo": "https://www.orientbell.com/media/img/logo.png",
      "contactPoint": [
        {
          "@type": "ContactPoint",
          "telephone": "1800 208 1015",
          "contactType": "customer service",
          "contactOption": "TollFree",
          "areaServed": "IN"
        },
        {
          "@type": "ContactPoint",
          "telephone": "+91-11-47119100",
          "contactType": "customer service"
        }
      ],
      "sameAs": [
        "https://www.facebook.com/OrientBellTiles/",
        "https://www.linkedin.com/company/orientbellltd",
        "https://twitter.com/OrientbellTiles",
        "https://www.youtube.com/user/Orientceramics"
      ],
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Iris House, 16 Business Centre, DDA Complex",
        "addressLocality": "Nangal Raya",
        "addressRegion": "New Delhi",
        "postalCode": "110046",
        "addressCountry": "India"
      }
    }
    `
  }
}
const handlePincodeFetch = async () => {
  try {
    if ('geolocation' in navigator) {
      const permission = await navigator.permissions.query({ name: 'geolocation' });

      if (permission.state === 'granted') {
        if (!cookie.get('pincode') && !cookie.get('removelocation')) {
          await retrieveGeolocation();
        }
      } else if (permission.state === 'prompt') {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            if (!cookie.get('pincode') && !cookie.get('removelocation')) {
              await retrieveGeolocation();
            }
          },
          (error) => {
            console.log('Error retrieving location:', error);
            // Handle error if needed
          }
        );
      } else {
        // Permission denied or unavailable
        console.log('Geolocation permission denied or unavailable.');
        // Handle denied permission or unavailable state
      }
    } else {
      console.log('Geolocation is not supported by this browser.');
      // Handle unsupported geolocation
    }
  } catch (error) {
    console.log('Error checking geolocation permission:', error);
    // Handle error if needed
  }
};
const retrieveGeolocation = async () => {
  try {
    const position = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        // timeout: 5000, // Set a timeout for geolocation retrieval (5 seconds)
      });
    });
    const { latitude, longitude } = position.coords;

    const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
    const address = response.data.address;

    if (address && address.postcode) {
      const pincode = address.postcode;
      localStorage.setItem("pincodeSkip", true);
      cookie.set('pincode', pincode);

      if (router.asPath.includes('/tiles')) {
        router.reload();
      }

      // If needed, you can call additional functions here (e.g., setPincode(pincode), getStores(pincode))
    }
  } catch (error) {
    console.log('Error retrieving location:', error);

    const errorMessage = error.message || 'Something went wrong while retrieving your location.';
    swal(errorMessage);
  }
};
let conical = ''
// console.log(router.asPath.split('?'))
if(router.asPath.split('?')[0].includes('/tiles') && router.asPath.split('?').length > 1){
  conical =  `${config.HEADLESS_BASE_URL}${router.asPath.split('?').slice(0, -1).join('')}` 
}else if(!router.asPath.includes('catalogsearch') && !router.asPath.includes('pincode-based-quotation')){
  conical = `${config.HEADLESS_BASE_URL}${router.asPath}`
} 

let isFollow = true
if(router.asPath.includes('catalog/category')){
  isFollow = false
  conical = `${config.HEADLESS_BASE_URL}/404`
}
if(router.asPath.includes('?')){
  isFollow = false
}
  return (
    //<Component {...pageProps} />
  // <ApolloProvider client={client}>
  
  <>
  {loading ?  <Loader /> :
  <>
    <Head>
    <meta name="Cache-Control" content="no-store, must-revalidate" />
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.7.1/jquery.min.js"></script>
    <script src="https://libraries.unbxdapi.com/sdk-clients/ss-unbxd-aapac-prod-orientbell53741713874410/ua/ua.js"></script>
      <link rel="icon" href="/favicon.webp" />
      {conical &&  <link rel="canonical" href={conical} />}
	  {/* <link rel="alternate" hreflang="en-us" href={`${config.HEADLESS_BASE_URL}${router.asPath}`} /> */}
	  <link rel="alternate" hreflang="hi-in" href={`${config.HINDI_HEADLESS_BASE_URL}${router.asPath}`} />
	  <link rel="alternate" hreflang="ta-in" href={`${config.TAMIL_HEADLESS_BASE_URL}${router.asPath}`} />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0" />
    {isFollow ? <meta name="robots" content="INDEX,FOLLOW"></meta> : <meta name="robots" content="noindex,nofollow"></meta>}
	  <meta name="facebook-domain-verification" content="t56u2w4eb323e4n3udtyy8gxxduqkz"></meta>
<script async 
          dangerouslySetInnerHTML={{
            __html: `
              (function(w,d,s,l,i){
                w[l]=w[l]||[];
                w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
                var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                j.async=true;
                j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-T95VCKR');
            `,
          }}
        />
        <script async 
      type="application/ld+json"
      dangerouslySetInnerHTML={orgSxhema()}
    />
              {/* <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
              <script async  dangerouslySetInnerHTML={{
                  __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${GA_TRACKING_ID}');
                  `,
                }}
              /> */}
              <script async 
            dangerouslySetInnerHTML={{
              __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window,document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '2918479641780898');
                fbq('track', 'PageView');`,
            }}
          />
    </Head>
    
  <Navbar />
  <Component {...pageProps} />
  {router.asPath == "/trialook" ? <TrialookFooter/> : <Footer {...pageProps}/> }
  </>}
  </>
  );
}

export default MyApp
