import Image from 'next/image'
import React from 'react'

const Loader = () => {
    return (
        <div className='obl-loader'>
            <Image src ='/loader.gif' alt ="loader" width='200' height='200'></Image>
        </div>
    )
}

export default Loader
